<template>
  <div class="mobile-app">
    <el-container>
      <el-header class="fixed-header">
        <el-button icon="el-icon-arrow-left" @click="goToHome" type="text" class="back-button"></el-button>
        <h1>我的报修</h1>
      </el-header>
      <el-main class="scrollable-main">
        <template v-if="reports.length === 0">
          <div class="no-data">暂无数据</div>
        </template>
        <template v-else>
        <el-card v-for="item in reports" :key="item.id" style="margin-bottom: 10px;">
          <div slot="header">
            <div>
              {{ getLineName(item.equipmentId) }} | {{ getEquipmentName(item.equipmentId) }}
              <el-tag :type="getTagType(item.state)" size="medium" style="float: right;">{{ item.state }}</el-tag>
            </div>
          </div>
          <div>
            <div><span>报修类型:{{ item.type }}</span></div>
            <div><span>报修时间：{{ item.recordTime }}</span></div>
            <div><span>故障描述:{{ item.equipmentName }}</span></div>
            <div><span>故障描述:{{ item.description }}</span></div>
          </div>
          <el-row type="flex" justify="center">
            <el-button @click="goToDetail(item.id)" size="large">查看</el-button>
<!--            <el-button v-if="item.ifcomment === true" type="primary" size="large" style="margin-left: 20px">已评价</el-button>-->
<!--            <el-button v-else type="primary" @click="comment(item.id)" size="large" style="margin-left: 20px">评价</el-button>-->
            <el-button type="primary" v-if="!haveComment(item.id)" @click="comment(item.id)" size="large" style="margin-left: 20px">评价</el-button>
          </el-row>
        </el-card>
        </template>
      </el-main>
      <!-- 底部导航栏 -->
      <el-footer class="fixed-footer">
        <el-row type="flex" justify="center">
          <el-col :span="8" class="footer-item">
            <router-link to="/mobile">首页</router-link>
          </el-col>
          <el-col :span="8" class="footer-item">
            <router-link to="/mequipment">设备知识库</router-link>
          </el-col>
          <el-col :span="8" class="footer-item">
            <router-link to="/mperson">个人信息</router-link>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
    <el-dialog title="评价" :visible.sync="dialogFormVisible" width="70%" >
      <el-form label-width="80px">
<!--        <el-form-item label="工单ID">-->
<!--          <el-input v-model="form.id" disabled></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="打分" v-if="!form.ifcomment">
          <div class="block">
            <el-rate
                v-model="value1"
                :colors="colors">
            </el-rate>
          </div>
        </el-form-item>
        <el-form-item label="打分" v-if="form.ifcomment">
          <div class="block">
            <el-rate
                disabled
                v-model="comments.value"
                :colors="colors">
            </el-rate>
          </div>
        </el-form-item>
        <el-form-item label="评价" v-if="!form.ifcomment">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="请输入内容"
              v-model="textarea1">
          </el-input>
        </el-form-item>
        <el-form-item label="评价" v-if="form.ifcomment">
          <el-input
              type="textarea"
              disabled
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="请输入内容"
              v-model="comments.comment">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button v-if="!form.ifcomment" type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "MyReport",
  data() {
    return {
      records: [],
      reports: [],
      allrecords: [],
      recordone: [],
      comments: [],
      lines: [],
      equipments: [],
      haveComments: [],
      dialogFormVisible: false,
      value1: null,
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      textarea1: '',
      form:{},
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    };
  },
  created() {
    this.load();
  },
  methods: {
    // 获取数据
    load() {
      this.request.get("/repairReport/findMyReport/"+this.user.id).then(res => {
        this.reports = res.data;
      });
      this.request.get("/equipment").then(res => {
        this.equipments = res.data;
      });
      this.request.get("/line").then(res => {
        this.lines = res.data;
      });
      this.request.get("/failureRecord").then(res => {
        this.allrecords = res.data;
      });
    },
    haveComment(id) {
      if(this.allrecords.find(v => v.reportId === id)){
        const record = this.allrecords.find(v => v.reportId === id);
        console.log(record.ifcomment)
        return record.ifcomment;
      }else{
        return false;
      }
    },
    comment(itemId) {
      this.request.get("/failureRecord/findByReportId/"+itemId).then(res => {
        this.records = res.data;
        if (this.records) {
          this.request.get("/comment/findRecord/"+this.records.id).then(res => {
            this.comments = res.data;
          });
          this.form = this.records
          this.dialogFormVisible = true
        } else {
          this.$message.warning("暂时不可评价");
        }
      });
    },
    goToHome() {
      this.$router.push('/mobile');
    },
    save() {
      const formData = {
        userId: this.user.id, // 报修人的 id
        value: this.value1,
        comment: this.textarea1,
        recordId: this.form.id,
      };
      this.request.post("/comment", formData).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.request.post("/failureRecord/ifcomment/"+this.form.id)
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    goToDetail(reportId) {
      this.request.get("/failureRecord/findByReportId/"+reportId).then(res => {
        this.recordone = res.data;
        if (this.recordone) {
          this.$router.push({ name: 'MRecordDetail', params: { id: this.recordone.id } });
        } else {
          this.$message.warning("暂时不可查看");
        }
      });
    },
    getLineName(equipmentId) {
      const equipment = this.equipments.find(e => e.id === equipmentId);
      if (equipment) {
        const line = this.lines.find(l => l.id === equipment.lineId);
        return line ? line.name : '';
      }
      return '';
    },
    getEquipmentName(equipmentId) {
      const equipment = this.equipments.find(e => e.id === equipmentId);
      return equipment ? equipment.name : '';
    },
    getTagType(state) {
      // 根据状态返回不同的标签类型（颜色）
      switch (state) {
        case '审批不通过':
          return 'danger';
        case '工单关闭':
          return 'success';
        case '作业结束':
          return 'info';
        case '维修中':
          return '';
        case '暂停中':
          return 'warning';
  }
}
  },
};
</script>

<style scoped>
.mobile-app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.fixed-header, .fixed-footer {
  background-color: rgb(38, 52, 69);
  color: #fff;
  text-align: center;
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 1000; /* 确保在顶部 */
}

.fixed-header {
  top: 0;
}

.fixed-footer {
  bottom: 0;
}

.scrollable-main {
  padding: 20px;
  overflow-y: auto;
  flex: 1;
  margin-top: 60px; /* 保证 el-main 在 el-header 下面 */
  margin-bottom: 60px; /* 保证 el-main 在 el-footer 上面 */
}

.footer-item {
  line-height: 40px;
  text-align: center;
}

.footer-item a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.footer-item a:hover {
  text-decoration: underline;
}

</style>